import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  static values = { id: String }
  static targets = [ "image", "video" ]
  play() {
    const videoPlayer = this.createVideoPlayer(this.imageTarget, this.idValue);
    this.videoTarget.appendChild(videoPlayer);
    this.imageTarget.style.display = "none";
  }
  createVideoPlayer(target, idValue){
    var iframe=document.createElement("iframe");
    iframe.setAttribute("src","https://www.youtube.com/embed/"+idValue+"?autoplay=1&autohide=1&border=0&wmode=opaque&enablejsapi=1");
    iframe.style.height=target.offsetHeight+"px";
    iframe.style.width="100%";
    iframe.style.border="none";
    return iframe;
  }
}