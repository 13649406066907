
import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  static values = {
    detail: String
  }
  // send event to mixpanel when clicked
  addEvent(event) {
    var text = event.target.outerHTML
    //mixpanel.track('Events', { content: this.detailValue, text: text })
  }
}