import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  addLoader(event) {
    // Prevent the form from submitting immediately
    event.preventDefault()
    // Find the submit button
    const buttonToDisable = this.element.querySelector('input[type="submit"]')
    if (buttonToDisable) {
      buttonToDisable.style.pointerEvents = 'none'
      buttonToDisable.style.cursor = 'wait'
      // Replace with custom loader
      buttonToDisable.value = '🐲 caricamento...'
    }

    // Submit the form after the loader is added
    // use requestSubmit() instead of submit() to permit turbo stuff
    this.element.querySelector('form').requestSubmit()
  }
}