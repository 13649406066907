import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  connect() {
    document.documentElement.setAttribute('data-bs-theme', this.getTheme())
    this.showThemeTextContent()
    this.changeLogoColor(this.getTheme())
  }

  switch(e) {
    e.preventDefault()
    const theme = this.getTheme() === 'dark' ? 'light' : 'dark'
    document.documentElement.setAttribute('data-bs-theme', theme)
    localStorage.setItem('theme', theme)
    this.showThemeTextContent()
    this.changeLogoColor(theme)

    // cambia il colore nel footer della pagina delle canzoni
    const bottomBar = document.getElementById('bottom-bar')
    if (bottomBar != null) {
      if (theme === 'dark') {
        bottomBar.style.backgroundColor = "#212529";
      } else {
        bottomBar.style.backgroundColor = "#ffffff";
      }
    }
  }

  getTheme() {
    const storedTheme = localStorage.getItem('theme')
    if (storedTheme) {
      return storedTheme
    }
    return window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'
  }

  showThemeTextContent(){
    const theme = this.getTheme()
    // cambio il testo del bottone
    this.element.textContent = theme === 'dark' ? ' Dark' : ' Light'
  }

  changeLogoColor(theme){
    const logo = document.getElementById('logo')
    if (theme === 'dark') {
      //logo.classList.add('invert')
    } else {
      //logo.classList.remove('invert')
    }
  }
}
