import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { anonymous: String };
  showInfo(){
    if(this.anonymousValue == "true"){
      // add node that warns the user that they are posting anonymously
      const anonymousWarning = document.createElement('div');
      anonymousWarning.innerHTML = `
        <small class='text-muted'>
        Stai scrivendo in forma anonima; se vuoi un profilo <a href="/users/sign_in">devi effettuare il login</a>.
        </small>
      `;
      this.element.prepend(anonymousWarning);

    }
  }
}